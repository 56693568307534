import React, { useState, useEffect, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';

import { useDimensions } from '../../hooks';

import styles from './HomeIntroduction.module.scss';
const cx = classnames.bind(styles);

const HomeIntroduction = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { windowWidth } = useDimensions();

  const content = useMemo(() => {
    const width = windowWidth * 0.64;
    const height = windowWidth * 0.2;

    const firstName = {
      width: windowWidth * 0.29,
      height: windowWidth * 0.06,
    };

    const description = {
      width,
      height: windowWidth * 0.03,
      x: 0,
      y: 100,
    };

    const lastName = {
      width: windowWidth * 0.35,
      height: windowWidth * 0.06,
    };

    return {
      width,
      height,
      items: [
        {
          width: firstName.width,
          height: firstName.height,
          x: (width - firstName.width) / 2,
          y: 0,
        },
        {
          width: description.width,
          height: description.height,
          x: (width - description.width) / 2,
          y: (height - description.height) / 2,
        },
        {
          width: lastName.width,
          height: lastName.height,
          x: (width - lastName.width) / 2,
          y: height - lastName.height,
        },
      ],
    }
  }, [windowWidth]);

  useEffect(() => {
    (async () => {
      try {
        await document.fonts.ready;
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  
  return (
    <>
      {isLoading ? (
        <ContentLoader
          speed={2}
          width={content.width}
          height={content.height}
          backgroundColor="#222"
          foregroundColor="#f7f164"
        >
          {content.items.map(({ x, y, width, height }) => (
            <rect x={x} y={y} rx="4" ry="4" width={width} height={height} />
          ))}
        </ContentLoader>
      ) : (
        <div className={cx('homeIntroduction')}>
          <div className={cx('homeIntroductionTitle')}>Narek</div>
          <div className={cx('homeIntroductionDescription')}>Full-Stack JavaScript Jedi</div>
          <div className={cx('homeIntroductionTitle')}>Keryan</div>
        </div>
      )}
    </>
  );
};

export default HomeIntroduction;
